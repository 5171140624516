import { useState, useMemo } from "react";
import {
  Box,
  Field,
  FontWeight,
  FormFieldStatus,
  Hint,
  HoverEffect,
  Input,
  Label,
  Option,
  PlainButton,
  Text,
  Select,
} from "@gocardless/flux-react";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { Trans } from "@lingui/macro";

import { FormWithMetadata, CustomField } from "./CustomMetadataField";

import { getInputErrorStatus } from "src/utils/inputValidation";
import { useOrganisation } from "src/queries/organisation";

interface MetadataKeyFieldProps {
  canRemoveAll: boolean;
  checkMetadataValuesForDuplicates: () => void;
  field: FieldArrayWithId<FormWithMetadata, "metadata", "id">;
  hideMetadataKeySelection: boolean;
  index: number;
  metadataChecked: boolean;
  customFieldsData?: CustomField[];
}

export const MetadataKeyField: React.FC<MetadataKeyFieldProps> = ({
  canRemoveAll,
  checkMetadataValuesForDuplicates,
  field,
  hideMetadataKeySelection,
  index,
  metadataChecked,
  customFieldsData,
}) => {
  const organisation = useOrganisation();
  const metadataKeys = useMemo(
    () => organisation?.common_metadata_keys?.customers || [],
    [organisation?.common_metadata_keys?.customers]
  );

  const options = useMemo(
    () => [
      ...new Set([
        ...(customFieldsData || []).map(({ name }) => name).filter(Boolean),
        ...metadataKeys,
      ]),
    ],
    [customFieldsData, metadataKeys]
  );

  const {
    register,
    formState: { errors },
  } = useFormContext<FormWithMetadata>();
  const [pickFromList, setPickFromList] = useState(!hideMetadataKeySelection);

  return (
    <>
      <Field>
        <Label htmlFor={`metadata.${index}.name`}>
          <Trans id="open-mandate.manual-creation.label-custom-field">
            New custom field
          </Trans>
        </Label>
        {metadataKeys.length > 0 && pickFromList ? (
          <Select
            {...register(`metadata.${index}.name`, {
              required: canRemoveAll ? false : metadataChecked,
              onChange: checkMetadataValuesForDuplicates,
            })}
            id={`metadata.${index}.name`}
            data-testid={`metadata.${index}.name`}
            defaultValue={field.name}
            status={getInputErrorStatus(!!errors.metadata?.[index]?.name)}
          >
            {options.map((key) => (
              <Option value={key} key={key}>
                {key ?? ""}
              </Option>
            ))}
          </Select>
        ) : (
          <Input
            {...register(`metadata.${index}.name`, {
              required: canRemoveAll ? false : metadataChecked,
              onChange: checkMetadataValuesForDuplicates,
            })}
            id={`metadata.${index}.name`}
            data-testid={`metadata.${index}.name`}
            type="text"
            defaultValue={field.name}
            status={getInputErrorStatus(!!errors.metadata?.[index]?.name)}
          />
        )}
        {errors.metadata?.[index]?.name ? (
          <Hint
            status={FormFieldStatus.Danger}
            data-testid="custom-field-error"
          >
            <Trans id="open-mandate.manual-creation.error-custom-field-required">
              Custom field is required
            </Trans>
          </Hint>
        ) : null}
      </Field>
      {!hideMetadataKeySelection && metadataKeys.length > 0 ? (
        <Box spaceAbove={0.5} spaceBelow={0.5}>
          <PlainButton
            effect={HoverEffect.TextDecoration}
            onClick={() =>
              setPickFromList((currentPickFromList) => !currentPickFromList)
            }
          >
            <Text weight={FontWeight.Bold}>
              {pickFromList ? (
                <Trans id="open-mandate.manual-creation.create-new-field">
                  Create new field
                </Trans>
              ) : (
                <Trans id="open-mandate.manual-creation.pick-from-list">
                  Pick from list
                </Trans>
              )}
            </Text>
          </PlainButton>
        </Box>
      ) : null}
    </>
  );
};
